import BigNumber from 'bignumber.js'
import { TypeUtils, WSModels } from 'carbon-js-sdk'
import { MarketConfig, MarketLiquidityUsageMultiplier } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/perpspool/market'
import { Pool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/perpspool/pool'
import { PerpPoolAPYEntry, UserVolume } from 'carbon-js-sdk/lib/insights' // eslint-disable-line import/no-unresolved
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { ethers } from 'ethers'
import { RecordOf } from 'immutable'

import { DurationOption } from 'js/constants/vaults'
import { SimpleMap } from 'js/utils/types'


export interface PerpPoolProps {
  pool: PerpPoolDetail
  registeredMarkets: MarketConfig[]
  performanceSnapshotTimeframe: DurationOption
  poolInfo: PerpPoolInfo
  vaultPerformanceData: VaultPerformanceData
  perpPoolApyStats?: PerpPoolAPYEntry
  volumeData: UserVolume[]
  openPositions: WSModels.Position[]
}

export interface SetPerpPoolProps extends PerpPoolProps {
  id: string,
}

export type VaultPerformanceData = Record<string, CleanedVaultPerformanceData[]>

export interface UpdatePerpPoolProps {
  poolId: string,
  poolInfo?: UpdatePerpPoolInfo,
  performanceSnapshotTimeframe?: DurationOption,
  vaultPerformanceData?: VaultPerformanceData
  perpPoolApyStats?: PerpPoolAPYEntry
  volumeData?: UserVolume[]
  openPositions?: WSModels.Position[]
}

export interface PoolIdentifier {
  id: string,
  performanceSnapshotTimeframes: DurationOption[]
}

export class PerpPoolDetail {
  private constructor(
    readonly id: number,
    readonly name: string,
    readonly depositDenom: string,
    readonly shareDenom: string,
    readonly vaultAddress: string,
    readonly supplyCap: BigNumber,
    readonly depositFee: BigNumber,
    readonly withdrawalFee: BigNumber,
    readonly baseBorrowFeePerFundingInterval: BigNumber,
  ) { } // eslint-disable-line no-empty-function

  static from(pool: Pool) {
    const {
      id,
      name,
      depositDenom,
      shareDenom,
      vaultAddress,
      supplyCap,
      depositFee,
      withdrawalFee,
      baseBorrowFeePerFundingInterval,
    } = pool
    return new PerpPoolDetail(
      id.toNumber(),
      name,
      depositDenom,
      shareDenom,
      vaultAddress,
      bnOrZero(supplyCap),
      bnOrZero(depositFee),
      bnOrZero(withdrawalFee),
      bnOrZero(baseBorrowFeePerFundingInterval),
    )
  }
}

export interface UpdatePerpPoolInfo {
  availableAmount?: BigNumber
  totalInPositionAmount?: BigNumber
  totalShareAmount?: BigNumber
  totalNavAmount?: BigNumber
  totalUpnlAmount?: BigNumber
}

export interface PerpPoolInfo {
  availableAmount: BigNumber
  totalInPositionAmount: BigNumber
  totalShareAmount: BigNumber
  totalNavAmount: BigNumber
  totalUpnlAmount: BigNumber
}

export interface CleanedVaultPerformanceData {
  time: number;
  cumulative: number;
  fundingRate: number;
  makerRebate: number;
  realizedPnl: number;
  dailyTotalPnl: number;
  takerFee: number;
  unrealizedPnl?: number;
}

export interface perpPoolApyStats {
  latestPrice: number;
  previousPrice: number;
  apy: number;
  from: string;
  to: string;
}

export interface FluoDistributorProps {
  totalStakedLpAmount: BigNumber,
  fluoPerSecond: BigNumber,
  totalBoostedShares: BigNumber,
  accumulatedFluoPerShare: BigNumber,
  lastClaimedRewardTime: number,
  startTime: number,
  accumulatedFluoPrecision: number,
  boostPrecision: number,
  maxBoostPrecision: number,
  userInfo: UserInfo,
}

// Similar to fluo distributor, variables fetched are required for estimated rewards received calculations
export interface RewardDistributorProps {
  fluoPerSecond: BigNumber,
  totalBoostedShares: BigNumber,
  accumulatedFluoPerShare: BigNumber,
  lastClaimedRewardTime: number,
  accumulatedFluoPrecision: number,
  boostPrecision: number,
  rewardSymbol: string,
  rewardDecimals: number,
  startTime: number,
  endTime: number,
  totalStakedLpAmount: BigNumber,
  rewardProxyAllowance: BigNumber,
  distributorAllowance: BigNumber,
  lpSymbol: string,
  lpDecimals: number,
}

export interface PLPIncentivesUserInfo {
  userInfo: UserInfo,
}

export interface BonusFluoDistributorProps {
  bonusTiers: SimpleMap<BonusTierInfo>,
  totalLockedBaseLPAmount: BigNumber,
  userLockedBaseLPAmount: BigNumber,
  longUnbondingPeriod: number,
  shortUnbondingPeriod: number,
  bonusFluoMultiplier: BigNumber,
  fluoBaseUnit: number,
}
export interface BonusUserWithdrawInfo {
  amount: BigNumber,
  initiateWithdrawTime: number,
}

export interface BonusUserWithdrawInfoRaw {
  amount: BigNumber,
  initiateWithdrawTime: number,
}
export interface BonusTierInfo {
  bonusFluoPerSecondPerLP: BigNumber,
  totalLockedLPAmount: BigNumber,
  totalPendingWithdrawLPAmount: BigNumber,
  maxLockedLPAmount: BigNumber,
  userInfo: BonusUserInfo,
  userWithdrawInfo: BonusUserWithdrawInfo[],
}

export interface BonusTierInfoRaw {
  bonusFluoPerSecondPerLP: ethers.BigNumber,
  totalLockedLPAmount: ethers.BigNumber,
  totalPendingWithdrawLPAmount: ethers.BigNumber,
  maxLockedLPAmount: ethers.BigNumber,
  userInfo: BonusUserInfoRaw,
  userWithdrawInfo: BonusUserWithdrawInfoRaw[],
}

export interface BonusUserInfoRaw {
  amount: BigNumber,
  isLongBondPeriod: boolean,
  lastClaimedTime: number,
  withdrawStartIdx: number,
  pendingWithdrawLPAmount: BigNumber,
}

export interface BonusUserInfo {
  amount: BigNumber,
  isLongBondPeriod: boolean,
  lastClaimedTime: number,
  withdrawStartIdx: number,
  pendingWithdrawLPAmount: BigNumber,
}

export interface BonusTierContractInfo {
  bonusFluoPerSecondPerLP: BigNumber,
  totalLockedLPAmount: BigNumber,
  totalPendingWithdrawLPAmount: BigNumber,
  maxLockedLPAmount: BigNumber,
}

export interface BonusTierContractInfoRaw {
  bonusFluoPerSecondPerLP: ethers.BigNumber,
  totalLockedLPAmount: ethers.BigNumber,
  totalPendingWithdrawLPAmount: ethers.BigNumber,
  maxLockedLPAmount: ethers.BigNumber,
}
export interface StFluoProps {
  userStFluoAmount: BigNumber,
  totalFluoAssetDeposited: BigNumber,
  totalSupply: BigNumber,
}

export interface EsFluoProps {
  userEsFluoAmount: BigNumber,
  totalStFluo: BigNumber,
  vestingDuration: number
  userUnlockedVestingAmount: BigNumber
  userLastVestUpdateTime: number,
  userStartVestTime: number,
  totalSupply: BigNumber,
}
export interface VeFluoProps {
  userVeFluoAmount: BigNumber,
  totalStFluo: BigNumber,
  maxVestingDuration: number,
  userVestingSchedule: VestingSchedule
  totalSupply: BigNumber,
}
export interface VestingSchedule {
  amount: BigNumber,
  startTime: number,
  endTime: number,
  releasedAmount: BigNumber,
  initialVEAmount: BigNumber,
}

export interface VestingScheduleRaw {
  amount: BigNumber,
  startTime: number,
  endTime: number,
  releasedAmount: BigNumber,
  initialVEAmount: BigNumber,
}
export interface FluoEmitterProps {
  fluoPerSecond: number,
  lastInitiateRedeemTime: number,
}
export interface UnbondingFluoProps {
  unbondingPeriod: number,
  userUnbondedAmount: BigNumber,
  userUnbondInfos: UnbondInfo[],
  userUnbondStartIdx: number,
}
export interface UnbondInfo {
  fluoAmount: BigNumber,
  startUnbondingTime: number,
}
export interface UnbondInfoRaw {
  amount: BigNumber,
  startUnbondingTime: number,
}
export interface FluoProps {
  userFluoAmount: BigNumber,
  fluoDecimals: number,
}
export interface VaultStateProps {
  perpPools: SimpleMap<PerpPoolProps>
  marketsLiquidityUsageMultiplier: MarketLiquidityUsageMultiplier[]
  launchVaultsPoolId: number,
  fluoDistributor: FluoDistributorProps
  bonusFluoDistributor: BonusFluoDistributorProps
  stFluo: StFluoProps,
  esFluo: EsFluoProps,
  veFluo: VeFluoProps,
  fluo: FluoProps,
  lp: LPProps,
  fluoEmitter: FluoEmitterProps,
  unbondingFluo: UnbondingFluoProps,
  perpPoolNetDeposits: TypeUtils.SimpleMap<BigNumber>
  // duplicate module of fluo
  perpPoolIncentivesContracts: TypeUtils.SimpleMap<IncentivesContracts>
  perpPoolIncentivesUserInfo: TypeUtils.SimpleMap<PLPIncentivesUserInfo>
  perpPoolRewardDistributorMap: TypeUtils.SimpleMap<RewardDistributorProps>
  perpPoolEvmBalanceMap: TypeUtils.SimpleMap<BigNumber>
}
export interface UserInfo {
  amount: BigNumber,
  rewardDebt: BigNumber,
  boostMultiplier: BigNumber,
}

export interface UserInfoRaw {
  amount: ethers.BigNumber,
  rewardDebt: ethers.BigNumber,
  boostMultiplier: ethers.BigNumber,
}

export interface PoolAmount {
  poolId: number,
  amount: BigNumber
}

export interface PerpPoolNetDeposits {
  netDeposits: BigNumber,
  totalDeposits: BigNumber,
  totalWithdrawals: BigNumber,
}

export interface LPProps {
  userLpAmount: BigNumber,
}

export interface LocalUpdateParams {
  poolId: number,
  change: BigNumber
}

export interface IncentivesContracts {
  rewardTokenAddress: string,
  lpContractAddress: string,
  rewardDistributorContract: string,
  denom: string,
}

export type VaultState = RecordOf<VaultStateProps>

export const PerpPoolsActionTypes = {
  SET_PERP_POOLS: '@vaults/SET_PERP_POOLS',
  SET_MARKETS_LIQUIDITY_USAGE_MULTIPLIER: '@vaults/SET_MARKETS_LIQUIDITY_USAGE_MULTIPLIER',
  SET_PERP_POOL_PERFORMANCE_SNAPSHOT_TIMEFRAME: '@vaults/SET_PERP_POOL_PERFORMANCE_SNAPSHOT_TIMEFRAME',
  SET_LAUNCH_VAULTS_POOL_ID: '@vaults/SET_LAUNCH_VAULTS_POOL_ID',
  UPDATE_PERP_POOL: '@vaults/UPDATE_PERP_POOL',
  // EVM contract-related data
  WATCH_FLUO_DISTRIBUTOR_DATA: '@vaults/WATCH_FLUO_DISTRIBUTOR_DATA',
  WATCH_ES_FLUO_DATA: '@vaults/WATCH_ES_FLUO_DATA',
  WATCH_ST_FLUO_DATA: '@vaults/WATCH_ST_FLUO_DATA',
  WATCH_VE_FLUO_DATA: '@vaults/WATCH_VE_FLUO_DATA',
  WATCH_UNBONDING_FLUO_DATA: '@vaults/WATCH_UNBONDING_FLUO_DATA',
  WATCH_FLUO_EMITTER_DATA: '@vaults/WATCH_FLUO_EMITTER_DATA',
  WATCH_BONUS_FLUO_DISTRIBUTOR_DATA: '@vaults/WATCH_BONUS_FLUO_DISTRIBUTOR_DATA',
  WATCH_FLUO_DATA: '@vaults/WATCH_FLUO_DATA',
  WATCH_LP_DATA: '@vaults/WATCH_LP_DATA',
  SET_LP_DATA: '@vaults/SET_LP_DATA',
  FETCH_PERP_POOL_DEPOSITS: '@vaults/FETCH_PERP_POOL_DEPOSITS',
  RELOAD_PERP_POOL_NET_DEPOSITS: '@vaults/RELOAD_PERP_POOL_NET_DEPOSITS',
  SET_PERP_POOL_NET_DEPOSITS: '@vaults/SET_PERP_POOL_NET_DEPOSITS',
  UPDATE_LOCAL_PERP_POOL_NET_DEPOSITS: '@vaults/UPDATE_PERP_POOL_NET_DEPOSITS',
  SET_ST_FLUO_DATA: '@vaults/SET_ST_FLUO_DATA',
  SET_ES_FLUO_DATA: '@vaults/SET_ES_FLUO_DATA',
  SET_UNBONDING_FLUO_DATA: '@vaults/SET_UNBONDING_FLUO_DATA',
  SET_VE_FLUO_DATA: '@vaults/SET_VE_FLUO_DATA',
  SET_FLUO_DATA: '@vaults/SET_FLUO_DATA',
  SET_FLUO_EMITTER_DATA: '@vaults/SET_FLUO_EMITTER_DATA',
  SET_FLUO_UNBONDING_PERIOD: '@vaults/SET_FLUO_UNBONDING_PERIOD',
  SET_USER_REWARD_DEBT: '@vaults/SET_USER_REWARD_DEBT',
  SET_USER_ES_FLUO_AMOUNT: '@vaults/SET_USER_ES_FLUO_AMOUNT',
  SET_USER_ST_FLUO_AMOUNT: '@vaults/SET_USER_ST_FLUO_AMOUNT',
  SET_USER_VE_FLUO_AMOUNT: '@vaults/SET_USER_VE_FLUO_AMOUNT',
  SET_ST_FLUO_SUPPLY: '@vaults/SET_ST_FLUO_SUPPLY',
  SET_VE_FLUO_SUPPLY: '@vaults/SET_VE_FLUO_SUPPLY',
  SET_ES_FLUO_SUPPLY: '@vaults/SET_ES_FLUO_SUPPLY',
  SET_TOTAL_FLUO_ASSET_DEPOSITED_IN_ST_FLUO: '@vaults/SET_TOTAL_FLUO_ASSET_DEPOSITED_IN_ST_FLUO',
  SET_TOTAL_ST_FLUO_IN_ES_FLUO: '@vaults/SET_TOTAL_ST_FLUO_IN_ES_FLUO',
  SET_TOTAL_ST_FLUO_IN_VE_FLUO: '@vaults/SET_TOTAL_ST_FLUO_IN_VE_FLUO',
  SET_USER_FLUO_UNBOND_INFOS: '@vaults/SET_USER_FLUO_UNBOND_INFOS',
  SET_USER_FLUO_UNBOND_START_IDX: '@vaults/SET_USER_FLUO_UNBOND_START_IDX',
  SET_ES_FLUO_VESTING_DURATION: '@vaults/SET_ES_FLUO_VESTING_DURATION',
  SET_ES_FLUO_USER_UNLOCKED_VESTING_AMOUNT: '@vaults/SET_ES_FLUO_USER_UNLOCKED_VESTING_AMOUNT',
  SET_ES_FLUO_USER_LAST_VEST_UPDATE_TIME: '@vaults/SET_ES_FLUO_USER_LAST_VEST_UPDATE_TIME',
  SET_ES_FLUO_USER_START_VEST_TIME: '@vaults/SET_ES_FLUO_USER_START_VEST_TIME',
  SET_VE_FLOW_MAX_VESTING_DURATION: '@vaults/SET_VE_FLOW_MAX_VESTING_DURATION',
  SET_VE_FLOW_USER_VESTING_SCHEDULE: '@vaults/SET_VE_FLOW_USER_VESTING_SCHEDULE',
  SET_FLUO_EMITTER_FLUO_PER_SECOND: '@vaults/SET_FLUO_EMITTER_FLUO_PER_SECOND',
  SET_FLUO_EMITTER_LAST_INITIATE_REDEEM_TIME: '@vaults/SET_FLUO_EMITTER_LAST_INITIATE_REDEEM_TIME',
  SET_FLUO_DISTRIBUTOR_START_TIME: '@vaults/SET_FLUO_DISTRIBUTOR_START_TIME',
  SET_FLUO_DISTRIBUTOR_FLUO_PER_SECOND: '@vaults/SET_FLUO_DISTRIBUTOR_FLUO_PER_SECOND',
  SET_FLUO_DISTRIBUTOR_USER_STAKED_LP_AMOUNT: '@vaults/SET_FLUO_DISTRIBUTOR_USER_STAKED_LP_AMOUNT',
  SET_FLUO_DISTRIBUTOR_USER_BOOST_MULTIPLIER: '@vaults/SET_FLUO_DISTRIBUTOR_USER_BOOST_MULTIPLIER',
  SET_FLUO_DISTRIBUTOR_TOTAL_BOOSTED_SHARES: '@vaults/SET_FLUO_DISTRIBUTOR_TOTAL_BOOSTED_SHARES',
  SET_FLUO_DISTRIBUTOR_ACCUMULATED_FLUO_PER_SHARE: '@vaults/SET_FLUO_DISTRIBUTOR_ACCUMULATED_FLUO_PER_SHARE',
  SET_FLUO_DISTRIBUTOR_LAST_CLAIMED_REWARD_TIME: '@vaults/SET_FLUO_DISTRIBUTOR_LAST_CLAIMED_REWARD_TIME',
  SET_FLUO_DISTRIBUTOR_TOTAL_STAKED_LP_AMOUNT: '@vaults/SET_FLUO_DISTRIBUTOR_TOTAL_STAKED_LP_AMOUNT',
  SET_FLUO_DISTRIBUTOR_BOOST_PRECISION: '@vaults/SET_FLUO_DISTRIBUTOR_BOOST_PRECISION',
  SET_FLUO_DISTRIBUTOR_ACCUMULATED_FLUO_PRECISION: '@vaults/SET_FLUO_DISTRIBUTOR_ACCUMULATED_FLUO_PRECISION',
  SET_FLUO_DISTRIBUTOR_DATA: '@vaults/SET_FLUO_DISTRIBUTOR_DATA',
  SET_BONUS_FLUO_DISTRIBUTOR_DATA: '@vaults/SET_BONUS_FLUO_DISTRIBUTOR_DATA',
  SET_BONUS_FLUO_USER_LOCKED_BASE_LP_AMOUNT: '@vaults/SET_BONUS_FLUO_USER_LOCKED_BASE_LP_AMOUNT',
  SET_BONUS_FLUO_TOTAL_LOCKED_BASE_LP_AMOUNT: '@vaults/SET_BONUS_FLUO_TOTAL_LOCKED_BASE_LP_AMOUNT',
  SET_BONUS_FLUO_BONUS_TIERS: '@vaults/SET_BONUS_FLUO_BONUS_TIERS',
  SET_USER_FLUO_AMOUNT: '@vaults/SET_USER_FLUO_AMOUNT',
  SET_USER_LP_AMOUNT: '@vaults/SET_USER_LP_AMOUNT',
  RELOAD_USER_LP_AMOUNT: '@vaults/RELOAD_USER_LP_AMOUNT',
  POLL_POOLS_PERFORMANCE: '@vaults/POLL_POOLS_PERFORMANCE',
  FETCH_REWARD_DISTRIBUTOR_DATA_MAP: '@vaults/FETCH_REWARD_DISTRIBUTOR_DATA_MAP',
  SET_REWARD_DISTRIBUTOR_DATA_MAP: '@vaults/SET_REWARD_DISTRIBUTOR_DATA_MAP',
  SET_PERP_POOL_INCENTIVES_CONTRACTS: '@vaults/SET_PERP_POOL_INCENTIVES_CONTRACTS',
  FETCH_REWARD_DISTRIBUTOR_DATA: '@vaults/FETCH_REWARD_DISTRIBUTOR_DATA',
  RELOAD_REWARD_DISTRIBUTOR_USER_STAKED_DATA: '@vaults/RELOAD_REWARD_DISTRIBUTOR_USER_STAKED_DATA',
  SET_PLP_EVM_BALANCE_MAP: '@vaults/SET_PLP_EVM_BALANCE_MAP',
  RELOAD_PERP_POOL_INCENTIVES_USER_LP_AMOUNT: '@vaults/RELOAD_PERP_POOL_INCENTIVES_USER_LP_AMOUNT',
  FETCH_PLP_INCENTIVES_USER_INFO: '@vaults/FETCH_PLP_INCENTIVES_USER_INFO',
  SET_PLP_INCENTIVES_USER_INFO: '@vaults/SET_PLP_INCENTIVES_USER_INFO',
  RELOAD_USER_STAKED_PLP_BALANCE: '@vaults/RELOAD_USER_STAKED_PLP_BALANCE',
}

export const VaultTasks = {
  QueryPerpPools: 'run-query-perp-pools',
  QueryPerpsPoolPerformance: 'query-perps-pool-performance',
  QueryPLPRewardsDistributor: 'query-plp-rewards-distributor',
}
